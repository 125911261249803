.personalize-dropdown {
  position: relative;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.personalize-dropdown button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 180px; /* Maximum width for larger screens */
  padding: 10px 15px;
  font-size: 1rem; /* Responsive font size */
  font-weight: 600;
  color: #fff;
  background-color: #0e4621;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.personalize-dropdown.enabled button {
  background-color: rgb(192, 165, 47);
  color: white;
}

.personalize-dropdown button:hover {
  background-color: rgb(192, 165, 47);
}

.personalize-dropdown ul {
  margin-top: 8px;
  background-color: #f9f9f9;
  padding: 0;
  border: 1px solid #ddd;
  text-align: left;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto; /* Automatically hide the scrollbar if not needed */
  z-index: 10;
}

.personalize-dropdown ul::-webkit-scrollbar {
  width: 8px;
}

.personalize-dropdown ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.personalize-dropdown ul::-webkit-scrollbar-thumb {
  background: #888;
}

.personalize-dropdown ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.personalize-dropdown li {
  display: block;
  color: #333;
  padding: 12px 15px;
  margin: 2px 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.personalize-dropdown li:hover {
  background-color: #e0e0e0;
}

.personalize-dropdown li.selected {
  background-color: rgb(191, 233, 142);
}

.personalize-dropdown li.selected:hover {
  background-color: #e0e0e0;
}

@media (max-width: 767px) {
  .personalize-dropdown button {
    font-size: 0.875rem; /* Slightly smaller font for smaller screens */
    max-width: 100%; /* Allow full width for smaller screens */
  }
}

/* Additional styles for ConsumerRecipeCard */

.consumerrecipecard {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 16px;
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.consumerrecipecard-title {
  font-size: 2rem;
  color: #0e4621;
  margin-bottom: 16px;
}

.consumerrecipecard-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

.consumerrecipecard-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .consumerrecipecard-options {
    flex-direction: column;
    gap: 8px;
  }

  .consumerrecipecard-title {
    font-size: 1.5rem;
  }
}

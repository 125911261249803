.applianceRowTableRow {
    border-bottom: 1px solid #ccc;
}

.applianceRowTableRow:hover {
    background-color: rgba(220, 220, 245, 25%)
}

.applianceRowImageContainer {
    display: block;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: linear-gradient(to top left, rgb(78, 78, 172) 30%,  lightblue 100%);
    overflow: hidden;
    border: 1px solid #666;
    transition: width 0.2s  ease-in-out, height 0.2s  ease-in-out, transform 0.3s ease-in-out;
}

.applianceImage {
    margin: 20px 0px 0px 20px;
    height: 80px;
    width: 80px;
    overflow: hidden;
}

.applianceRowTableCell {
    font-size: 13pt;
    border: none;
}

.applianceRowTableCell.image {
    width: 15%;
}

.applianceRowTableCell.model {
    width: 10%;
}

.applianceRowTableCell.name {
    width: 15%;
}

.applianceRowTableCell.type {
    width: 10%;
}

.applianceRowTableCell.capabilities {
    width: 20%;
}

.applianceRowTableCell.activeCount {
    width: 12%;
}

.applianceRowTableCell.status {
    width: 10%;
}

.applianceRowTableCell.status.status-production {
    color: green;
}

.applianceRowTableCell.status.status-staging {
    color: darkgray;
}

.applianceRowTableCell.manageButton {
    width: 10%;
}

button.manageApplianceButton {
    width: 128px;
}

.manageButtonIcon {
    color: grey;
    padding-right: 8px;
}

.consumerrecipecard {
  /* border: 1px solid #ccc; */
  border-radius: 12px;
  /* width: 1250px; */
  text-align: left;
  /* display: flex;
  flex-direction: column; */
  /* background-color: #fcfcfc; */
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  color: #444;
  padding-bottom: 24px;
} 

.consumerrecipecard-header {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 1px solid #ddd;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  /* padding-top: 48px; */
}

.video-container {
  position: relative;
  width: 90%;
  padding-bottom: 10%;
  overflow: hidden;
  border-radius: 12px;
  z-index: 1;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.consumerrecipecard-info {
  width: 100%;
  margin-top: -38px;
  padding-top: 56px;
  z-index: 0;
}

.social-icon {
  color: #444444;
  height: 20px;
  margin-left: 16px;  
}

.social-icon:hover {
  color:#0c5212;
}

.consumerrecipecard-data {
  width: 90%;
  /* display: inline-flex; */
  padding: 18px;
  z-index: 0;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-bottom: 32px;
  align-items: center;
  justify-content: center;
  background-color: rgba(252, 255, 252, 0.6);
}

.consumerrecipecard-data .entry {
  height: 100%;
  text-align: center;
}

.consumerrecipecard-data .divider {
  border-right: 1px solid #ccc;
  height: 64px;
  padding-right: 64px;
  margin-right: 64px;
  text-align: center;
}

.consumerrecipecard-data .entry h4 {
  color: green;
  text-transform: uppercase;
  font-size: 10pt;
}

.consumerrecipecard-data .entry p {
  font-size: 18pt;
  font-weight: 350;
}

.consumerrecipecard-title {
  /* font-size: 44px; */
  /* color: #333; */
  width: 100%;
  box-sizing: border-box;
  margin-top: 16px;
  margin-bottom: 4px;
  /* font-weight: 500; */
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  z-index: 0;
}

:target:before {
  content: "";
  display: block;
  height: 120px;
  margin: -120px 0 0;
}

.consumerrecipecard-options {
  /* position: relative; */
  /* width: 100%; */
  /* display: flex; */
  /* padding-left: 6%;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 24px; */
  background: linear-gradient(to right,  #0c5212 0%,  #3ba958 35%);
  /* background-color: white; */
  /* max-height: 84px; */
}

.consumerrecipecard-options p {
  font-size: 14pt;
  font-weight: 200;
  color: white;
  /* padding-right: 44px; */
  /* align-self: center; */
}

.consumerrecipecard-creator-image {
  height: 32px;
  padding-right: 8px;
  color:#52b788;
}

/**/

.consumerrecipecard-loading-header {
  height: 360px;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
  background-image: url("../../public/loading-header-background.jpg");
  background-repeat: repeat;
  background-size: cover;
  object-fit: cover;
  opacity: 40%;
  border-bottom: 1px solid #ccc;
}

.consumerrecipecard-loading-body {
  display: flex;
  background-color: #fafafa;
  line-height: 36px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #333333;
  font-size: 16pt;
  font-weight: 400;
  height: 720px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

/**/

.consumerrecipecard h1 {
  /* font-size: 24pt; */
  font-weight: 300;
  /* color: #28650f; */
  /* color: #3ba958; */
  /* line-height: 2em; */
  /* margin-top: 12px; */
}

.consumerrecipecard h2 {
  /* font-size: 18pt; */
  font-weight: 400;
  color: #3ba958;
  /* line-height: 2em; */
  /* margin-bottom: 12px; */
}

.consumerrecipecard p {
  /* line-height: 1.5em; */
  /* font-size: 16pt; */
  /* display: block;  */
}

.consumerrecipecard-tagline {
  /* margin-bottom: 24px; */
  margin-right: 4px;
  /* font-size: 16pt; */
  font-weight: 500;
}

.consumerrecipecard-description {
  /* margin-bottom: 32px; */
}

.consumerrecipecard-source {
  font-size: 16pt;
  margin-bottom: 12px;
  color: darkslategray;
}

/****/

.consumerrecipecard .listSection {
  /* margin-bottom: 36px; */
}

.consumerrecipecard .listSection ul {
  list-style-type: none;
  padding-left: 0;
}

.consumerrecipecard .listSection li {
  position: relative;
  padding-left: 4px;
  margin-bottom: 8px; 
}

.consumerrecipecard .listSection label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
}

.consumerrecipecard .listSection li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.consumerrecipecard .listSection li label:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #999;
  border-radius: 4px;
}

.consumerrecipecard .label-icon {
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  font-size: 14pt;
  line-height: 1.6em;
  align-items: center;
}

.appliance-icon-container {
  display: block;
  width: 36px;
  height: 36px;
  margin-right: 8px;
  border-radius: 50%;
  background: linear-gradient(to top left, rgb(78, 78, 172) 30%,  lightblue 100%);
  overflow: hidden;
  border: 1px solid #666;
  transition: width 0.2s  ease-in-out, height 0.2s  ease-in-out, transform 0.3s ease-in-out;
}

.appliance-icon {
  margin: 6px 0px 0px 6px;
  height: 32px;
  width: 32px;
  overflow: hidden;
}


.consumerrecipecard .listSection li input[type="checkbox"]:checked + label:before {
  background-color: #3ba958;
  border-color: #3ba958;
}

.consumerrecipecard .listSection li label:after {
  content: '\2713'; /* checkmark */
  font-size: 16pt;
  color: #fff;
  position: absolute;
  top: 0;
  left: 3px;
  visibility: hidden;
}

.consumerrecipecard .listSection li input[type="checkbox"]:checked + label:after {
  visibility: visible;
}

.consumerrecipecard .listSection li label span {
  /* font-size: 14pt;
  line-height: 1.6em; */
}

.consumerrecipecard .listSection li input[type="checkbox"]:checked + label span {
  text-decoration: line-through;
}

.consumerrecipecard .listSection li .icon {
  position: absolute;
  top: 6px;
  right: -24px;
  opacity: 0;
  height: 20px;
  color: darkred;
  transition: opacity 0.05s ease;
}

.consumerrecipecard .listSection li:hover .icon {
  opacity: 1;
}

/* .consumerrecipecard-card .required-items-container {
  display: flex;
  align-items: flex-start;
} */

.consumerrecipecard-card .required-items-content {
  width: 100%;
}

.consumerrecipecard .required-items-image {
  padding-top: 20px;
}

.consumerrecipecard .required-items-image img {
  /* max-width: 90%; */
  max-height: 640px;
  /* max-width: 480px; */
  height: auto;
  border-radius: 6px;
  object-fit: contain;
  border: 1px solid grey;
  /* margin-left: 36px; */
}

/***/

.consumerrecipecard .recipe-navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 5% 32px 5%;
  border-top: 1px solid #eee;
  background-color: #fafafa;
  border-radius: 0 0 12px 12px;
}

.consumerrecipecard .recipe-navigation-container .page-buttons {
  list-style: none;
  display: flex;
  font-size: 8pt;
}

.consumerrecipecard .recipe-navigation-container .page-buttons li {
  margin: 0 8px;
}

.consumerrecipecard .recipe-navigation-container .page-buttons li button {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  color: #999;
  background-color: #fdfdfd;
  text-decoration: none;
  cursor: pointer;
}

.consumerrecipecard .recipe-navigation-container .page-buttons li button:hover {
  background-color: #3ba958;
  color: white;
  cursor: pointer;
}

.consumerrecipecard .recipe-navigation-container .page-buttons li button.active {
  background-color: #3ba958;
  color: white;
  cursor: pointer;
}

.consumerrecipecard .recipe-previous-button,
.consumerrecipecard .recipe-next-button {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #52b788; 
  border: none;
  color: white;
  font-size: 20pt;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ladle-magic-icon {
  color: rgb(255, 255, 255);
  margin-right: 8px;
  width: 40px;
}

.set-personalize-button {
  /* height: 44px; */
  /* width: 170px; */
  /* margin-left: 16px;
  margin-right: 36px; */
  /* padding-left: 12px; */
  /* padding-right: 12px; */
  border-radius: 22px;
  background-color: #40c640;
  /* background-color: rgb(68, 189, 57);  */
  /* border: 1px solid #518856; */
  color: white;
  /* font-size: 11pt; */
  transition: background-color 0.2s ease;
  width: 120px;
}

.set-personalize-button:hover {
  background-color: #32cd32;
  cursor: pointer;
}

.set-personalize-button[disabled] {
  background-color: #B6E3A1;
  color: #FFFFFF99;
  cursor:default;
}

.set-personalize-button[disabled]:hover {
  background-color: #B6E3A1;
  color: #FFFFFF99;
  cursor:default;
}

.set-personalize-button-mobile {
  align-self: center;
  height: 44px;
  width: 140px;
  /* margin-left: 16px;
  margin-right: 36px; */
  /* padding-left: 12px; */
  /* padding-right: 12px; */
  border-radius: 10px;
  background-color: #79449f;
  /* background-color: rgb(68, 189, 57);  */
  /* border: 1px solid #518856; */
  color: white;
  /* font-size: 11pt; */
  transition: background-color 0.2s ease;
}

.set-personalize-button-mobile:hover {
  background-color: #b64f06;
  cursor: pointer;
}

.set-personalize-button-mobile[disabled] {
  background-color: #0e4621;
  color: #FFFFFF99;
  cursor:default;
}

.set-personalize-button-mobile[disabled]:hover {
  background-color: #0e4621;
  color: #FFFFFF99;
  cursor:default;
}
.consumerrecipecard-video-button {
  color: white;
  font-size: 12pt;
  cursor: pointer;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  color: #eda6a6;
  height: 24px;
  margin-left: 12px;
}

.consumerrecipecard-video-button:hover {
  color: white;
  font-size: 12pt;
  cursor: pointer;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  color: #e42d2d;
  height: 24px;
  margin-left: 12px;
}


/**/

.recipe-previous-button:disabled,
.recipe-next-button:disabled {
  cursor: none;
  background-color: grey;
}

.consumerrecipecard .recipe-previous-button:hover,
.consumerrecipecard .recipe-next-button:hover {
  background-color: #3ba958;
}

.consumerrecipecard .recipe-previous-button:disabled:hover,
.consumerrecipecard .recipe-next-button:disabled:hover {
  cursor: unset;
  background-color: grey;
}

/**/

.consumerrecipecard-card-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.consumerrecipecard-card-wrapper {
  display: flex;
  transition: transform 0.3s ease;
}

.consumerrecipecard-step-divider {
  width: 100%;
  border-bottom: 1px solid #EEE;
}

.consumerrecipecard-main-divider {
  width: 100%;
  border-bottom: 1px solid #c8d9cd;
  margin-top: 20px;
  margin-bottom: 20px;
}

.consumerrecipecard-video-container {
  display: flex;
  margin-bottom: 44px;
}

.consumerrecipecard-video-thumbnail {
  align-items: center;
  justify-content: center;
  margin: 12px;

}

.consumerrecipecard-video-thumbnail img {
  height: 96px;
  border: 1px solid rgb(192, 192, 192);
  border-radius: 5px;
}

.consumerrecipecard-video-thumbnail img:hover {
  height: 96px;
  border: 1px solid grey;
  border-radius: 5px;
  transition: all 0.2s ease; /* add transition effect */
  transform: scale(1.03); /* increase size on hover */
  cursor: pointer;
}

.consumerrecipecard-video-thumbnail-label {
  display: inline-flex;
  width: 100%;
  font-size: 14pt;
  justify-content: center;
}

.consumerrecipecard-card {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  /* padding-top: 20px;
  padding-bottom: 8px;
  padding-left: 7%;
  padding-right: 7%;   */
}

.consumerrecipecard-steps-section-header {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
}

.consumerrecipecard-steps-section {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  /* padding-left: 7%;
  padding-right: 7%; */
}

.consumerrecipecard-steps-section-step-first {
  /* padding-top: 0px; */
  padding-bottom: 15px;
}

.consumerrecipecard-steps-section-step {
  /* padding-top: 32px; */
  padding-bottom: 15px;
}

/**/

p.consumerrecipecard-step-text {
  /* padding-top: 0px; */
  padding-bottom: 12px;
  list-style: none;
  align-items: center;

}

.consumerrecipecard-step-text li {
  white-space: pre-wrap;
  margin-bottom: 8px;
}

.consumerrecipecard-step-text .icon {
  /* height: 4px; */
  /* color: gold; */
  padding-right: 16px;
  vertical-align: middle;
}

/**/
.stepItem-link {
  text-decoration: none !important;
}
.ingredient-name {
  text-decoration: underline;
}

.ingredient-target {
  transition: background-color 0.25s ease;
}

.ingredient-target:target {
  background-color:rgba(48, 218, 32, 0.75);
  transition-delay: 0.5s;
}

.cookware-target {
  transition: background-color 0.25s ease;
}

.cookware-target:target {
  background-color:rgba(83, 128, 223, 0.781);
  transition-delay: 0.5s;
}

.appliance-target {
  transition: background-color 0.25s ease;
}

.appliance-target:target {
  background-color:rgba(185, 45, 45, 0.863);
  transition-delay: 0.5s;
}

.supply-target {
  transition: background-color 0.25s ease;
}

.supply-target:target {
  background-color:rgba(48, 218, 32, 0.75);
  transition-delay: 0.5s;
}

/**/

a.stepItem-bubble {
  display: inline-block;
  padding: 0px 10px;
  align-items: center;
  font-size: 14pt;
  justify-content: center;
  /* border-radius: 16px; */
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); */
  text-decoration: none;
  color: black;
  transition: background-color 0.1s ease;
}

/* a.stepItem-bubble.ingredient { background-color: rgb(124, 192, 46); }
a.stepItem-bubble.ingredient:hover { background-color: rgb(56, 92, 15); }

a.stepItem-bubble.cookware { background-color: rgb(46, 158, 192); }
a.stepItem-bubble.cookware:hover { background-color: rgb(65, 98, 219); }

a.stepItem-bubble.appliance { background-color: rgb(218, 85, 85); }
a.stepItem-bubble.appliance:hover { background-color: rgb(177, 30, 30); }

a.stepItem-bubble.supply { background-color: rgb(46, 158, 192); }
a.stepItem-bubble.supply:hover { background-color: rgb(65, 98, 219); } */

a.stepItem-link {
  display: inline-block;
  justify-content: center;
  text-decoration: underline;
  font-weight: 450;
}
/* a.stepItem-link:hover {
  text-decoration: underline;
} */
/* 
a.stepItem-link.ingredient:hover { text-decoration-color: rgb(158, 196, 116) }
a.stepItem-link.cookware:hover { text-decoration-color: rgb(65, 98, 219); }
a.stepItem-link.appliance:hover { text-decoration-color: rgb(177, 30, 30); }
a.stepItem-link.supply:hover { text-decoration-color: rgb(65, 98, 219); } */

/**/

.consumerrecipecard-tags {
  /* margin-bottom: 32px; */
  display: inline-flex;
  align-items: center;
}

/* loading.css */
/* loading.css */
/* loading.css */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes stir {
  0%, 100% { transform: rotate(0deg); }
  25% { transform: rotate(15deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-15deg); }
}

@keyframes textGlow {
  0%, 100% {
    color: #2d3748; /* Dark gray */
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff;
  }
  50% {
    color: #38b2ac; /* Teal */
    text-shadow: 0 0 20px #38b2ac, 0 0 30px #38b2ac, 0 0 40px #38b2ac;
  }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f7fafc; /* Light gray background */
}

.loading-box {
  text-align: center;
  padding: 2rem;
  background-color: #ffffff; /* White background */
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* Shadow */
}

.loading-text {
  font-size: 1.5rem;
  font-weight: 600; /* Semi-bold */
  margin-bottom: 1rem;
}
.loading-text-animation{
  animation: textGlow 2s ease-in-out infinite;
}

.personalization-text {
  font-size: 1rem;
  color: #a0aec0; /* Light gray text */
  margin-bottom: 2rem;
}

#food {
  animation: food 0.5s ease-out infinite alternate;
  animation-delay: 0.5s;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes food {
  from {
    transform: rotateZ(-10deg) translateY(45px) scale(1);
  }
  to {
    transform: translateY(-40px) scale(1.5);
  }
}

#pan {
  animation: pan 0.5s ease-in infinite alternate;
}

@keyframes pan {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20px);
  }
}

#steel {
  animation: steel 0.5s ease-out infinite alternate-reverse;
  transform-origin: bottom;
  transform-box: fill-box;
}

@keyframes steel {
  from {
    transform: rotateZ(-5deg);
  }
  to {
    transform: rotateZ(5deg);
  }
}

.shadow {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  /* Similar syntax to box-shadow */
}

.chef {
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
}

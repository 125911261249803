.sort-dropdown {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.sort-dropdown-label {
  color: #777;
  margin-bottom: 6px;
  text-decoration: none;
  font-size: 11pt;
  font-weight: 350;
  user-select: none;
  display: flex;
  align-items: center;
}

.sort-label-icon {
  height: 12px;
  color: #333;
  margin-right: 6px;
}

.sort-dropdown-button {

    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    justify-content: center;
    min-width: 160px;
    

    color: #555;
    text-decoration: none;
    font-size: 13pt;
    font-weight: 350;
    user-select: none;

    background-color: #eaeaea;
    border-radius: 8px;
    border: 1px solid #cccccc00;
    padding: 10px 12px 10px 12px;

}

.sort-dropdown-button:hover {
  border-radius: 8px;
  border: 1px solid #cccccc;
}

.sort-dropdown-icon {
  height: 12px;
  color: #333;
  margin-right: 12px;
}

.sort-dropdown ul {

  z-index: 999;
  opacity: 0%;
  transition: opacity 0.1s ease, transform 0.25s ease, max-height 0.25s ease;

  max-height: 0px;
  min-width: 160px;
  padding: 4px 4px 4px 4px;
  display: none;
  position: absolute;
  top: 105%;

  font-size: 12pt;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

}

.sort-dropdown ul.visible {
  display: block;
  opacity: 1;
  max-height: 1000px;
}

.sort-dropdown li {
  display: flex;
  align-items: center;
  color: #333;
  padding: 12px 12px;
  margin: 4px 2px;;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
}

.sort-dropdown li:hover {
  background-color: rgb(209, 235, 214);
}

.sort-list-icon {
  height: 12px;
  color: #333;
  margin-right: 8px;
}
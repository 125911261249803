
.account-app {
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
}

.account-app-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    animation: changeBackground 40s infinite;
    transition: background-image 1s ease-in-out;
    z-index: 1;
}

.account-app a {
    text-decoration: underline;
}

.account-app a:hover {
    color: darkgreen;
}

.account-app .section-content {
    z-index: 2;
    padding: 2rem;
}

.account-app footer {
    z-index: 9;
    color: #666;
}

.account-app .logo {
    margin-left: 40px;
    height: 72px;
    margin-bottom: 24px;
}

.account-tile {
    padding: 5vw;
    border-radius: 12px;
    background-color: #fdfdfd;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    justify-content: flex-start;
    border: 1px solid #ddd;
    height: inherit;
    margin: inherit;    
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 72px;
}

/* .account-tile.login {
    padding-left: 72px;
    padding-right: 72px;
} */

/* .account-tile.register {
    padding-left: 72px;
    padding-right: 72px;
} */

.account-tile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 8px;
}

.account-app .account-error {
    color: darkred;
    font-weight: 600;
    margin-top: 6px;
    margin-bottom: 12px;
}

.account-app .account-success {
    color: rgb(31, 99, 5);
    font-weight: 600;
    margin-top: 6px;
    margin-bottom: 12px;
    text-align: center;
}

.account-app .account-success-redirecting{
    color: #333;
    font-weight: 600;
    margin-bottom: 12px;
    text-align: center;
}


.account-app .instruction-text {
    align-content: left;
    color: #333;
    font-weight: 200;
}

.account-app .help-text {
    margin-top: 36px;
}

.account-box {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    width: 300px;
}

.account-box h2 {
    text-align: center;
}

.form-group {
    margin-bottom: 16px;
}

.form-group label {
    display: block;
    margin-bottom: 6px;
}

.account-app form {
    margin-top: 24px;
}

.account-app.login form {
    /* width: 390px; */
}

.account-app.register form {
    /* width: 450px; */
}

.account-app input[type="text"],
.account-app input[type="email"],
.account-app input[type="password"] {
    padding: 16px;
    font-size: 12pt;
    width: 100%;
    border: 1px solid #ccc;
    margin-top: 6px;
    border-radius: 5px;
    display: inline-block;
    border: 1px solid #aaa;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    font-weight: 300;
}

.account-app button[type="submit"] {
    width: 100%;
    font-size: 12pt;
    background-color: #0e4621; /* ladle green */
    color: #fff;
    border: none;
    padding: 16px 20px;
    border-radius: 32px;
    cursor: pointer;
    font-weight: 100;
}

.account-app button[type="submit"]:hover {
    background-color: #3e8e41;
}

/**/

.account-app input[type="text"]:disabled,
.account-app input[type="url"]:disabled,
.account-app input[type="email"]:disabled {
    background-color: #efefef;
    cursor: not-allowed;
}

.account-app input[type="url"].icon {
    padding-left: 48px;
}

.account-app .input-container {
    position: relative;
}

.account-app .input-icon {
    position: absolute;
    top: 50%;
    height: 20px;
    left: 16px;
    transform: translateY(-30%);
    color: #999;
}

input::placeholder,
textarea::placeholder {
    font-size: 14pt;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ccc;
    font-weight: 400;
}

/**/


@keyframes changeBackground {

    0% {
        opacity: 0.25;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/Slow-Cooker-Vegetarian-Lentil-Chili-decked-out.jpg');
    }

    1% {
        opacity: 1;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/Slow-Cooker-Vegetarian-Lentil-Chili-decked-out.jpg');
    }

    24% {
        opacity: 1;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/Slow-Cooker-Vegetarian-Lentil-Chili-decked-out.jpg');
    }

    25% {
        opacity: 0.25;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/Slow-Cooker-Vegetarian-Lentil-Chili-decked-out.jpg');
    }

    26% {
        opacity: 0.25;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/sweet-potato-black-bean-tacos-2.jpg');
    }

    27% {
        opacity: 1;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/sweet-potato-black-bean-tacos-2.jpg');
    }

    49% {
        opacity: 1;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/sweet-potato-black-bean-tacos-2.jpg');
    }

    50% {
        opacity: 0.25;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/sweet-potato-black-bean-tacos-2.jpg');
    }

    51% {
        opacity: 0.25;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/rice-noodles-with-cashew-sauce-and-crunchy-veg.webp');
    }

    52% {
        opacity: 1;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/rice-noodles-with-cashew-sauce-and-crunchy-veg.webp');
    }

    74% {
        opacity: 1;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/rice-noodles-with-cashew-sauce-and-crunchy-veg.webp');
    }

    75% {
        opacity: 0.25;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/rice-noodles-with-cashew-sauce-and-crunchy-veg.webp');
    }

    76% {
        opacity: 0.25;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/WGC-Key-Lime-Pie-0-copy-3.jpg');
    }

    77% {
        opacity: 1;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/WGC-Key-Lime-Pie-0-copy-3.jpg');
    }

    99% {
        opacity: 1;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/WGC-Key-Lime-Pie-0-copy-3.jpg');
    }

    100% {
        opacity: 0.25;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0.9) 100%), url('/public/background-images/WGC-Key-Lime-Pie-0-copy-3.jpg');
    }
}

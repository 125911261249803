.shortcuts {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 12px 24px 24px 0px;
  justify-content: left;
  /*border: 1px solid red;*/
}

.shortcut-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 28px 12px 14px;
  background-color: #fefefe;
  border: 1px solid #ddd;
  border-radius: 50px;
  color: #333;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  margin: 4px 20px 0px 0px;
  transition: background-color 0.3s ease;
}

.shortcut-button:hover {
  background-color: #ddd;
}

.shortcut-button a {
  text-decoration: none;
}

.shortcut-button a:visited {
  text-decoration: none;
  color: #333;
  font-size: 20px;
  font-weight: 500;
}

.shortcut-button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: lightgreen;
  border: 0px solid #333;
  border-radius: 50%;
  margin-right: 10px;
}

.shortcut-button-icon-small {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background-color: lightgreen;
  border: 0px solid #333;
  border-radius: 50%;
  margin-right: 10px;
  padding: 6px;
}

.shortcut-button i {
  font-size: 20px;
}

.shortcut-button span {
  margin-left: 10px;
}

.shortcut-button button:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

/**/

.insights-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 24px;
  column-gap: 24px;
}

.insight-tile {
  height: 300px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 312px;
  margin: 0 auto;
  background-color: #fdfdfd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.insight-tile p {
  margin: 20px 24px 0px 24px;
}

.insight-tile button {
  margin-top: 24px;
  width: 120px;
}

.grid-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: left;
  text-align: center;
  align-items: left;
  height: 100%;
  margin: 0 0;
  /*border: 1px solid #000;*/
}

.metric-container {
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: left;
  flex-direction: column;
  height: 100%;
}

.metric-tile {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fefefe;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-image: url("https://www.transparenttextures.com/patterns/clean-textile.png");
  justify-content: center;
  border: 1px solid #ddd;
}

.metric-tile img {
  border-radius: 12px;
  height: auto;
}
.menu-dropdown {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: center;  
}

.menu-dropdown ul::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 62px;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #ffffff;
}

.menu-dropdown ul::after {
  content: "";
  position: absolute;
  top: -9px;
  left: 61px;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  border-bottom: 9px solid #ccc;
  z-index: -1;
}

.menu-dropdown-link {

    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    justify-content: center;

    color: white;
    text-decoration: none;
    margin: 0 12px;
    font-size: 17pt;
    font-weight: 350;
    user-select: none;

}

.menu-dropdown-link:hover {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: rgb(238, 229, 192);
    /* text-underline-offset: 38px; */
}

.menu-dropdown ul {

  z-index: 999;
  opacity: 0%;
  transition: opacity 0.1s ease, transform 0.25s ease, max-height 0.25s ease;

  max-height: 0px;
  min-width: 240px;
  padding: 4px 4px 4px 4px;
  display: none;
  position: absolute;
  top: 140%;

  font-size: 12pt;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

}

.menu-dropdown ul.visible {
  display: block;
  opacity: 1;
  max-height: 190px;
}

.menu-dropdown li {
  display: block;
  color: #333;
  padding: 12px 12px;
  margin: 6px 2px;;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
}

.menu-dropdown li:hover {
  background-color: rgb(209, 235, 214);
}
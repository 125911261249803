.recipecard {
  border: 1px solid #ccc;
  border-radius: 12px;
  width: 1250px;
  text-align: left;
  display:flex;
  flex-direction: column;
  background-color: #fcfcfc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.recipecard-header {
  height: 360px;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
  object-fit: cover;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  /*border: 1px red solid;*/
}

.recipecard-loading-header {
  height: 360px;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
  background-image: url("../../public/loading-header-background.jpg");
  background-repeat: repeat;
  background-size: cover;
  object-fit: cover;
  opacity: 40%;
  border-bottom: 1px solid #ccc;
}

.recipecard-loading-body {
  display: flex;
  background-color: #fafafa;
  line-height: 72px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #333333;
  font-size: 16pt;
  font-weight: 400;
  height: 720px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.recipecard-header .header-image {
  width: 100%;
  height: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  object-fit: cover;
  z-index: -1;;
}

.recipecard-header .icon-close {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 24px;
  height: 32px;
  width: 32px;
  fill: #FFFFFF;
  stroke: #000000;
}

.recipecard-title {
  font-size: 36px;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.0) 100%);
  color: #fff; /* Set the caption text color */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px; /* Add padding to the caption to separate it from the image */
  padding-top: 64px;
  box-sizing: border-box; /* Include padding in the width of the element */
  font-weight: 400;
  z-index: 0;
}

.recipecard-creator-tools {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding-left: 56px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #3ba958;
  max-height: 80px;
}

.recipecard-creator-tools p {
  font-size: 14pt;
  font-weight: 200;
  color: white;
  padding-right: 196px;
  align-self: center;
}


/**/

.recipecard-options {
  padding-top: 8px;
  padding-left: 48px;
  padding-right: 48px;
}

.recipecard-data {
  width: 100%;
  display: inline-flex;
  padding: 18px;
  z-index: 0;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-bottom: 32px;
  align-items: center;
  justify-content: center;
  background-color: rgba(252, 255, 252, 0.6);
}

.recipecard-data .divider {
  border-right: 1px solid #ccc;
  height: 64px;
  padding-right: 64px;
  margin-right: 64px;
  text-align: center;
}

.recipecard-data .entry {
  height: 100%;
  text-align: center;
}

.recipecard-data .entry h4 {
  color: green;
  text-transform: uppercase;
  font-size: 10pt;
}

.recipecard-data .entry p {
  font-size: 18pt;
  font-weight: 350;
}

.recipecard-details {
  line-height: 20pt;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
}

.recipecard-details h2 {
  padding-bottom: 12px;
}

.recipecard h1 {
  font-size: 24pt;
  font-weight: 300;
  color: #28650f;
  line-height: 2em;
  margin-bottom: 12px;
}

.recipecard h2 {
  font-size: 18pt;
  font-weight: 400;
  color: #3ba958;
  line-height: 2em;
  margin-bottom: 12px;
}

.recipecard-source {
  color: darkslategray;
  font-size: 16pt;
  font-weight: 200;
}

ul.recipecard-ingredients {
  list-style-type: disc;
}

ul.recipecard-instructions {
  list-style-type:decimal;
}

.recipecard-metadata {
  padding: 0px 0px 24px 0px;
  font-size: 14pt;
  font-weight: 360;
  line-height: 2em;
}

.recipecard-description {
  padding: 0px 0px 24px 0px;
  font-size: 14pt;
  font-weight: 360;
}

.recipecard h2 {
  font-size: 18pt;
  font-weight: 280;
  color: #3ba958;
  line-height: 2em;
}

.recipecard-ingredients,
.recipecard-instructions {
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 0px;
  padding-bottom: 24px;
  list-style: none;
  font-size: 14pt;
  font-weight: 360;
}

.recipecard-ingredients li,
.recipecard-instructions li {
  white-space: pre-wrap;
  margin-bottom: 8px;
}

.recipecard-instructions li {
  white-space: pre-wrap;
  margin-bottom: 16px;
}

.recipecard-creator-name {
  color: darkslategray;
  font-size: 16pt;
  font-weight: 200;
}

/**/

.recipecard .listSection {
  margin-bottom: 36px;
  /* min-width: 60%;
  flex: 1; */
}

.recipecard .listSection ul {
  list-style-type: none;
  padding-left: 0;
}

.recipecard .listSection li {
  position: relative;
  padding-left: 4px;
  margin-bottom: 8px; 
}

.recipecard .listSection label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
}

.recipecard .listSection li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.recipecard .listSection li label:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #999;
  border-radius: 4px;
}

.recipecard .listSection li input[type="checkbox"]:checked + label:before {
  background-color: #3ba958;
  border-color: #3ba958;
}

.recipecard .listSection li label:after {
  content: '\2713'; /* Checkmark character */
  font-size: 16pt;
  color: #fff;
  position: absolute;
  top: 0;
  left: 3px;
  visibility: hidden;
}

.recipecard .listSection li input[type="checkbox"]:checked + label:after {
  visibility: visible; /* Show checkmark when checkbox is checked */
}

.recipecard .listSection li label span {
  font-size: 14pt;
  line-height: 1.6em;
}

.recipecard .listSection li input[type="checkbox"]:checked + label span {
  text-decoration: line-through; /* Apply strikethrough when checkbox is checked */
}

.recipecard .listSection li .icon {
  position: absolute;
  top: 6px;
  right: -24px;
  opacity: 0;
  height: 20px;
  color: darkred;
  transition: opacity 0.05s ease;
}

.recipecard .listSection li:hover .icon {
  opacity: 1;
}

.recipecard .required-items-container {
  display: flex;
  align-items: flex-start;
}

.recipecard .required-items-content {
  width: 100%;
}

.recipecard .required-items-image {
  padding-top: 20px;
}

.recipecard .required-items-image img {
  max-width: 90%;
  max-height: 640px;
  max-width: 480px;
  height: auto;
  border-radius: 6px;
  object-fit: contain;
  border: 1px solid grey;
}

.recipecard-tags {
  display: inline-flex;
  align-items: center;
  margin-bottom: 24px;
}
.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 32px;
  box-sizing: border-box;
}

.previous-button,
.next-button {
  padding: 16px;
  border-radius: 36px;
  border: none;
  background-color: #2a7c44;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  width: 120px;
}

.previous-button {
  margin-right: auto;
}

.next-button {
  margin-left: auto;
}

.previous-button:disabled,
.next-button:disabled {
  opacity: 0.3;
  cursor: default;
}
.margin-left {
  margin-left: 20px;
}
.previous-button:hover:enabled,
.next-button:hover:enabled {
  background-color: #5daa76;
}

.d-sm {
  display: none !important;
}
.d-lg button{
/* same width button */
width: 220px;
}

@media (max-width: 640px) {
  /* Reduce padding and margins for smaller screens */
  .profile-section {
    padding: 16px;
    text-align: center;
  }

  .profile-section img {
    display: block;
    margin: 0 auto;
  }

  .social-icons {
    display: flex;
    justify-content: center;
  }
.mobile-grid {
padding: 20px;
}
  .enter-contest-btn {
    width: 100%;
    padding: 8px 12px;
  }
.menubar-logo {
  height: 65px;
  width: 125px;
  margin: 0 auto;
  padding-left: 20px;
}
.mobile-menu {
  display: flex;
  justify-content: center;

}
.change-font {
  font-size: 25px;
}
.mobile-menu img {
  display: block;
  margin: 0 auto;
}
.d-lg {
  display: none !important;
}
.d-lg {
  display: none !important;
}
.d-sm {
  display: block !important;
}
.d-sm button {
  margin-bottom: 20px;
  margin-left: 0;

  width: 220px;
}
.margin-left {
  margin-left: 0px;
}
  .pagination-controls {
    width: 70%;
    display: flex !important;
    justify-content: space-between !important;
    margin: 0 auto;
  }
}



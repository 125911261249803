.addApplianceRowTableRow {
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.addApplianceRowTableRow:hover {
    background-color: rgba(220, 220, 245, 25%);
}

.addApplianceRowImageContainer {
    display: block;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: linear-gradient(to top left, rgb(78, 78, 172) 30%,  lightblue 100%);
    overflow: hidden;
    border: 1px solid #666;
    transition: width 0.2s  ease-in-out, height 0.2s  ease-in-out, transform 0.3s ease-in-out;
}

.addApplianceImage {
    margin: 20px 0px 0px 20px;
    height: 80px;
    width: 80px;
    overflow: hidden;
}

.addApplianceRowTableCell {
    font-size: 13pt;
    border: none;
}

.addApplianceRowTableCell.image {
    width: 20%;
}

.addApplianceRowTableCell.model {
    width: 70%;
}

.addApplianceRowTableCell.spacer {
    width: 10%;
}
  .floating-menu-creator {
    border-radius: 8px;
    flex: 1;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    height: 85%;
    background-image: url('../../public/floating-menu-bg2.png'), linear-gradient(to bottom, #fdfdfd, #fff);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .floating-menu-brand {
    border-radius: 8px;
    flex: 1;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    height: 85%;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid #b4a14440;
  }

  .floating-menu-image {
    margin-top: 30%;
    padding: 32px;
    text-align: center;
  }

  .brand-image {
    max-height: 96px;
    max-width: 144px;
}

  .floating-menu-header {
    text-align: center;
    position: relative;
  }

  .floating-menu-section {
    margin-top: 20%;
  }

  .floating-menu-content {
    margin-bottom: 30px;
    margin-left: 64px;
    display: flex;
    align-items: center;
  }

  .floating-menu-icon {
    flex: 0 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 24px;
    height: 32px;
  }

  .floating-menu-metric {
    flex: 1;
  }

  .floating-menu h1 {
    font-weight: 700;
    font-size: 16pt;
  }

  .floating-menu h2 {
    font-weight: 300;
    font-size: 14pt;
  }

  .floating-menu h4 {
    font-weight: 500;
    color: #bbb;
    font-size: 10pt;
    margin-bottom: 2px;
  }

  .floating-menu h5 {
    font-weight: 480;
    font-size: 19pt;
    color: #333;
  }

  /**/


  .floating-menu-creator h1 {
    font-weight: 700;
    font-size: 16pt;
  }

  .floating-menu-creator h2 {
    font-weight: 300;
    font-size: 14pt;
  }

  .floating-menu-creator h4 {
    font-weight: 500;
    color: #bbb;
    font-size: 10pt;
    margin-bottom: 2px;
  }

  .floating-menu-creator h5 {
    font-weight: 480;
    font-size: 19pt;
    color: #333;
  }

  /**/

  .floating-menu-brand h1 {
    font-weight: 700;
    font-size: 16pt;
  }

  .floating-menu-brand h2 {
    font-weight: 300;
    font-size: 14pt;
  }

  .floating-menu-brand h4 {
    font-weight: 500;
    color: #bbb;
    font-size: 10pt;
    margin-bottom: 2px;
  }

  .floating-menu-brand h5 {
    font-weight: 480;
    font-size: 19pt;
    color: #333;
  }

.consumer-profile .tile {
    min-width: 1024px;
}

  .consumer-profile h2 {
    color: #333;
    margin-bottom: 24px;
  }

  .consumer-profile label {
    margin-top: 4px;
    display: block;
  }

  .consumer-profile input, select, textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .consumer-profile button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .consumer-profile button:hover {
    background-color: #45a049;
  }
.recipe-grid {
  /* display: grid;
  row-gap: 30px;
  column-gap: 30px; */
}

.recipe-grid a {
  text-decoration: none;
  font-size: inherit;
  color: inherit;
}
header {
    
    height: auto;
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* z-index: 999; */

    /* animation: headerSlideDown 0.3s ease-out forwards; */

}

header.search-active {
    /* max-height: 200px; */
}

.logo {
    height: 60px;
}

/** top-bar **/

.menu-bar {
    /* padding: 9px 40px 9px 40px;
    background-color: #fefefe;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center; */
    
}

/***/


.user-menu {
    /* display: flex;
    align-items: center; */
    cursor: pointer;
    position: relative;
    /* justify-content: center; */
}

.user-menu-button {
    /* display: flex;
    flex-direction: row;
    padding: 6px 16px 6px 16px;
    min-height: 80px;
    border-radius: 32px;
    align-items: center; */
    border: 1px solid rgba(255,255,255,0);
}

.user-menu-button:hover {
    background-color: rgba(255, 225, 255, 0.10);
    border: 1px solid #ccc;
    /* cursor: pointer; */
}

.user-menu ul {

    display: none;
    position: absolute;
    top: 95%;
    /* left: 50%; */
    /* transform: translateX(-49%); */

    padding: 4px 4px 4px 4px;
    width: 216px;

    font-size: 12pt;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

}

.user-menu ul.visible {
    display: block;
    z-index: 1000;
}

.user-menu li {
    display: block;
    color: #333;
    padding: 14px 12px;
    margin: 4px 4px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
}

.user-menu li:hover {
    background-color: lightgray;
}

.user-menu li.selected {
    background-color: rgb(240, 192, 37);
}

.user-menu li.selected:hover {
    background-color: lightgray;
}

.menuDivider {
    border-left: 1px solid #dddddd;
    height: 32px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 6px;
}

.menuIcon {
    color: #0e4621;
    margin-right: 10px;
}

.user-profile-image {
    max-height: 56px;
    max-width: 56px;
    align-content: center;
    justify-content: center;
    width: auto;
    border-radius: 50%;
    border: 1px solid #ddd;
}

.user-menu-icon {
    width: 24px;
    margin-right: 8px;
    color: grey;
}

.brand-profile-image {
    max-height: 64px;
    align-content: center;
    justify-content: center;
    max-width: 128px;
}

.user-info {
    margin-right: 10px;
    margin-left: 10px;
    text-align: right;
}

.user-name {
    font-size: 12pt;
    font-weight: 400;
    user-select: none;
}

.user-handle {
    font-size: 10pt;
    font-weight: 300;
}

.menu {
    /* display: flex;
    flex-grow: 0.97;
    margin: 0px 16px 0px 16px;
    justify-content: center; */
}

.menu a {

    /* display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    justify-content: center;

    color: #333;
    text-decoration: none;
    margin: 0 8px;
    font-size: 17pt;
    font-weight: 350;
    user-select: none; */

}

.menu a:hover,
.menu a:hover.selected {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: rgb(238, 229, 192);
    text-underline-offset: 38px;
}
.menu svg {
    border: '1px solid red' !important
}
.menu a.selected {
    /* text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: goldenrod;
    text-underline-offset: 38px; */
}

/***/

.addContentButton {
    padding: 24px;
}

/** search **/

.search-bar {
    display: flex;
    /* border-top: 1px solid #eee; */
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* background-color: grey; */
    animation: slideDown 0.3s ease-out forwards;
    /* padding: 12px 40px 12px 40px; */
    top: 0;
    left: 0;
    width: 100%;
}

.search-bar.visible {
    max-height: 0;
}

.search-bar textarea {
    flex-grow: 1;
    font-size: 14pt;
    /* width: 1280px; */
    color: #666;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 4px;
}

.search-bar textarea:focus {
    outline: 1px solid goldenrod;
}


@keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* 
@keyframes headerSlideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
} */

.search-bar-container {
    /* position: absolute;
    top: 100%;
    width: 100%;
    transform: translateY(-100%); */
    transition: transform 0.8s ease-in-out;
}

.search-bar-visible {
    transform: translateY(0);
}
 .custom-menu > a {
    font-size: 17pt;
 }

.custom-placeholder::placeholder {
    font-size: 16px; 
    color: #999; 
}
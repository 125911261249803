.recipetile {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  /* width: 290px; */
  /* margin: 0 0; */
  background-color: #fafafa;
  /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);*/
  transition: all 0.2s ease; /* add transition effect */
  border:none;
}

.recipetile:hover {
  background-color: #ffffff; /* change background color on hover */
  box-shadow: 6px 6px 6px rgba(33, 100, 7, 0.1);
  /*box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);*/
  /*transform: scale(1.03); /* increase size on hover */
}

.recipetile-title {
  /* overflow: hidden; */
  width: 100%;
  /* height: 24px; */
  margin: 0 auto;
  /* font-size: 16px; */
  font-weight: bold;
  /* margin-top: 6px; */
}

.recipetile-stats {
  font-size: 10pt;
  padding-top: 12px;
  color: #888;
}

.recipe-photo {
  border-radius: 50%;
  height: 56px;
  width: 56px;
  object-fit: cover;
  border: 1px solid goldenrod;
}

/**/

.recipetile-image {
  /* height: 300px; */
  overflow: hidden;
}

.recipetile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recipetile-details {
  /* padding: 20px; */
  overflow: hidden;
  /* height: auto; */
  background-color: white;
  border: none;
}

.recipetile-author {
  overflow: hidden;
  width: 100%;
  height: 24px;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 360;
  margin-bottom: 12px;
}

.recipetile-tags {
  font-size: 8pt;
  /* height: 16px; */
}


.recipetile {
  position: relative; /* Add position relative to enable absolute positioning of the heart icon */
  /* Other existing styles remain unchanged */
}

.recipetile .heart-icon {
  position: absolute;
  top: 10px; /* Adjust top position as needed */
  right: 10px; /* Adjust right position as needed */
  z-index: 1; /* Ensure heart icon appears above other content */
  cursor: pointer;
}


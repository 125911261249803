:root {
  --brand-color: #5c8596;
  --brand-color-2: #5c859633;
}

.consumerrecipecard-appliance-control {
  border-radius: 12px;
  color: #444;
  display: flex;
  flex-direction: column;
  box-shadow: 3px 3px 3px rgba(7, 40, 100, 0.1);
  margin: 16px 20px 16px 20px;
  border: 1px solid #999;
}

/**/

.consumerrecipecard-appliance-control-header {
  display: inline-flex;
  background: linear-gradient(to right, var(--brand-color) 50%, var(--brand-color-2) 100%);
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.consumerrecipecard-appliance-control-header-left {
  display: flex;
  align-items: center;
  border-top-left-radius: 12px;
}

.consumerrecipecard-appliance-control-header-right {
  display: flex;
  align-items: center;
}

.consumerrecipecard-appliance-control-header-icon {
  align-items: center;
  padding: 20px 32px 20px 32px;
  background: linear-gradient(to right, rgb(14, 70, 33) 50%, rgba(0, 0, 0, 0) 100%);
  border-top-left-radius: 12px;
}

.consumerrecipecard-appliance-control-header-title {
  align-items: center;
  margin-left: 16px;
  padding: 20px 0px 20px 0px;
}

.consumerrecipecard-appliance-control-details {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px 16px 136px;
  background-color: rgba(234, 238, 241, 0.66);
  width: 100%;
  font-size: 12pt;
  font-weight: 300;
  border-top: 1px solid #aaa;
}

.consumerrecipecard-appliance-control-details .padded {
  min-height: 68px;
  padding-bottom: 12px;
}

.consumerrecipecard-appliance-control-details .detail-box {
  border: 1px solid rgb(192, 192, 192);
  border-radius: 8px;
  padding: 1px 5px 2px 5px;
  background-color: #bbbbcc33;
}

.detail-box {
  border: 1px dashed rgb(192, 192, 192);
  align-items: center;
  display: inline-flex;
  border-radius: 8px;
  padding: 1px 5px 2px 5px;
  font-size: 13pt;
  background-color: #b1b1d83f;
}

.detail-box:hover {
  background-color: #b1d8b93f;
  cursor: pointer;
}


.consumerrecipecard-appliance-control-details .details-text {
  display: flex;
  align-items: center;
  gap: 4px;
}

.consumerrecipecard-appliance-control-details .adjust-settings-button {
  margin-right: 16px;
  cursor: pointer;
  padding: 8px 18px 8px 10px;
  border: 1px solid #aaa;
  border-radius: 26px;
  background: #eee;
  font-size: 10pt;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-items: center;
}

.consumerrecipecard-appliance-control-details .adjust-settings-button:hover {
  border: 1px solid #aaa;
  background-color: rgba(84, 192, 228, 0.1);
}

/**/

.consumerrecipecard-appliance-control-buttons {
  padding: 0px 0px 24px 136px;
  background-color: rgba(234, 238, 241, 0.66);
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.consumerrecipecard-appliance-control-expand-row {
  display: flex;
  justify-content: flex-start;
  background-color: rgba(234, 238, 241, 0.66);
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}


/***/

.consumerrecipecard-appliance-control-body {

  color: #444;
  display: flex;
  flex-direction: column;

  /* padding: 28px 32px 32px 72px; */

  background-image:
    linear-gradient(to right, rgba(255, 255, 255, 35%) 0%, rgba(255, 255, 255, 35%) 60%, rgba(219, 222, 240, 97%) 69%, rgba(224, 227, 245, 85%) 100%),
    url('../../../public/brands/bosch/appliances/HBL8443UC.png'),
    linear-gradient(to bottom, rgba(86, 94, 209, .106) 0%, rgba(86, 94, 209, .106) 65%, rgba(198, 205, 245, 90%) 85%, rgba(198, 205, 245, 95%) 100%);

  background-position: 0 0, right -15% top -24px, 0 0;
  background-repeat: no-repeat;
  background-size: cover, 40%, cover;

  width: 100%;
  align-items: flex-start;
  overflow: hidden;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  /* transition: padding 0.66s cubic-bezier(0, 1, 0, 1), opacity 1s ease; */

}

.consumerrecipecard-appliance-control-settings {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  transition: padding-top 0.66s cubic-bezier(0, 1, 0, 1), opacity 1s ease;
}

.consumerrecipecard-appliance-control select {
  width: 480px;
  padding: 12px 8px 12px 12px;
  margin-bottom: 14px;
  border-radius: 12px;
  font-size: 12pt;
  display: inline-block;
  border: 1px solid #aaa;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  font-weight: 400;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.consumerrecipecard-appliance-control select:hover {
  /* background-color: rgba(178, 182, 253, 0.116); */
  border: 1px solid #333;
}

.consumerrecipecard-appliance-control label {
  padding-right: 24px;
  display: inline-block;
  width: 160px;
  font-weight: 500;
  font-size: 11pt;
}

/**/

.consumerrecipecard-appliance-control h4 {
  color: #ffffff;
  font-weight: 550;
  font-size: 13pt;
  user-select: none;
}

.consumerrecipecard-appliance-control button {
  border-radius: 24px;
  min-width: 200px;
  max-width: 480px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 11pt;
  padding: 20px 28px 20px 28px;
}

.consumerrecipecard-appliance-control button.activate {
  background: linear-gradient(157deg, rgba(245, 245, 245, 0.99) 0%, rgba(250, 250, 250, 0.98) 78%);
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: width 1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

}

.consumerrecipecard-appliance-control button.activate:hover {
  border: 1px solid #bbb;
  background: rgba(84, 192, 228, 0.1);
}

.consumerrecipecard-appliance-control button.activate::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0%;
  background: linear-gradient(157deg, rgba(233, 187, 36, 0.85) 0%, rgba(241, 225, 86, 0.88) 100%);
  transition: width 0s linear;
}

.consumerrecipecard-appliance-control button.activate.is-active::before {
  width: 100%;
  transition: width 2s linear;
  z-index: -1;
}

.consumerrecipecard-appliance-control .ladle-magic-icon {
  color: rgb(255, 255, 255);
  margin-right: 16px;
  width: 40px;
  user-select: none;
}

img.ladle-stars-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
} 

.consumerrecipecard-appliance-control img.brand-platform-logo {
  display: block;
  height: 28px;
  padding-right: 24px;
  user-select: none;
}

.consumerrecipecard-appliance-control img.brand-platform-logo:hover {
  scale: 102%;
  transition: scale 0.66s cubic-bezier(0, 1, 0, 1);
}

.settings-icon {
  width: 24px;
}

/***/

.header-icon-expanded {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 0px;
}

.header-icon-collapsed {
  border-top-left-radius: 12px;
}

.settings-expanded {
  max-height: 1000px;
  padding: 28px 32px 16px 136px;
  overflow: hidden;
}

.settings-collapsed {
  overflow: hidden;
  max-height: 0px;
  padding: 0px;
}


/****/
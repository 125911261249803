a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

a.hover.blue { 
    color: #0f1899;
    font-weight:600;
}

a.hover.blue:hover { 
    color: #0f1899;
    text-decoration: underline;
}

a.hover.green:hover { 
    color: #1f990f
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    overflow-y: scroll;
}

/* Set default font family, size and color */
html,
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: #333;
    background-color: #f9f9f9;
}

/**/

.content-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
    text-align: left;
    /* min-width: 1296px; */
    max-width: 1296px;
}

.content-header {
    display: flex;
    flex-direction: row;
    /* justify-content:  space-between; */
    align-items: end;
    margin-bottom: 12px;
}
.content-header-for-appliances {
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content:  space-between; */
    /* align-items: end; */
    margin-bottom: 12px;
}

.content-title {
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 24pt;
    font-weight: 300;
    margin-bottom: 4px;
}

.content-description {
    /* font-size: 16pt; */
    color: #888888;
}

.content-menu-bar {
    margin: 8px 0px 12px 0px;
    color: #888888;
}

.container {
    display: inline-flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

/**/

/* .tile {
    padding: 20px;
    border-radius: 12px;
    justify-content: flex-start;
    height: inherit;
    margin: inherit;
} */

.form-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}

.form-section h3 {
    font-size: 20pt;
    font-weight: 400;
    margin-bottom: 12px;
}

.form-section p {
    margin-top: 12px;

}
/* 
.form-section input[type="text"] {
} */

/**/

input[type="text"],
input[type="url"],
input[type="email"],
input[type="password"]  {
    /* width: 1080px; */
    padding: 14px;
    margin-top: 12px;
    margin-bottom: 7px;
    border-radius: 20px;
    font-size: 14pt;
    display: inline-block;
    /* border: 1px solid #aaa; */
    /* -moz-box-sizing: border-box; */
    /* -webkit-box-sizing: border-box; */
    /* box-sizing: border-box; */
    background-color: #fff;
    font-weight: 400;
}

input[type="text"]:disabled,
input[type="url"]:disabled,
input[type="email"]:disabled {
    background-color: #efefef;
    cursor: not-allowed;
}

input[type="url"].icon {
    padding-left: 48px;
}

.search-icon {
    padding-left: 56px;
    border: none;
}

input[type="text"].search-icon:focus {
    padding-left: 56px;
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

input[type="text"].icon:focus {
    padding-left: 56px;
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/* input[type="text"]:focus {
    outline: none;
    box-shadow: 1px 1px 1px 1px
} */

.input-container {
    position: relative;
}

.input-icon {
    position: absolute;
    top: 43%;
    height: 20px;
    left: 20px;
    transform: translateY(-30%);
    color: #999;
}

input::placeholder,
textarea::placeholder {
    font-size: 14pt;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ccc;
    font-weight: 400;
}

select {
    width: 1080px;
    padding: 14px;
    margin-bottom: 18px;
    border-radius: 5px;
    font-size: 14pt;
    display: inline-block;
    border: 1px solid #aaa;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    font-weight: 400;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

textarea {
    width: 1080px;
    padding: 14px;
    margin-top: 12px;
    margin-bottom: 16px;
    border-radius: 5px;
    font-size: 14pt;
    border: 1px solid #aaa;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    font-weight: 400;
    color: #333;
}

textarea:disabled {
    background-color: #efefef;
    cursor: not-allowed;
}

textarea.tall {
    height: 320px;
}

textarea.short {
    height: 120px;
}

button.outline {
    min-width: 100px;
    padding: 12px;
    border-radius: 25px;
    border: 1px solid #bbb;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.outline.selected {
    /* align-self: center; */
    background-color: #fce9e6;
}

button.outline.selectedgreen {
    /* align-self: center; */
    background-color: #f3fce6;
}

button.outline.requested {
    margin: 12px 24px;
    background-color: #d5f0d5;
}

/* button.outline-green {
    min-width: 100px;
    padding: 12px;
    border-radius: 24px;
    border: 1px solid #bbb;
    background-color: #eee;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12pt;
    color: #363;
}

button.outline-green:hover {
    background-color: #aaeeac;
} */

button.outline:hover {
    background-color: #ccc;
}

button.green {
    background-color: #3e8e41;
    border: none;
    color: #fff;
    margin-top: 16px;
    padding: 16px 32px;
    border-radius: 32px;
    font-size: 18px;
    cursor: pointer;
    width: 200px;
}

button.green:hover {
    background-color: #0e4621;
}
.newgreen {
    background-color: #0e4621;
    border: none;
    color: #fff;
    margin-top: 16px;
    padding: 12px 26px;
    border-radius: 32px;
    font-size: 18px;
    cursor: pointer;
    width: 100px;
}

.newgreen:hover {
    background-color: #3e8e41;
}

button.white {
    width: 100px;
    align-self: center;
    margin: 12px 24px;
    padding: 12px;
    border-radius: 25px;
    border: 1px solid #bbb;
    background-color: #eee;
    cursor: pointer;
}

button.white:hover {
    background-color: #999;
}

button.white.requested{
    border: 1px solid #bbb;
    background-color: #d5f0d5;
}

button.green-white {
    padding: 12px 24px 12px 24px;
    border-radius: 24px;
    background-color: #fff;
    color: darkgreen;
}

button.green-white:hover {
    background-color: #adebaf;
}

button.gold {
    background: linear-gradient(45deg, #ffd700, #e7b24f, #faa742);
    border: none;
    color: #fff;
    padding: 16px 32px;
    border-radius: 32px;
    font-size: 18px;
    cursor: pointer;
    width: 200px;
}

button.gold:hover {
    background: linear-gradient(45deg, rgba(255, 215, 0, 0.6), rgba(255, 165, 0, 0.6), rgba(255, 140, 0, 0.6));
}

button:disabled,
button[disabled] {
    background-color: grey;
    cursor:none;
}

button:disabled,
button[disabled]:hover {
    background-color: #a99e9e;
    cursor:auto;
}

/**/

.removeFieldIcon {
    height: 24px;
    margin-left: 16px;
    cursor: pointer;
}

.removeFieldIcon:hover {
    color: green;
}

.profile-photo {
    width: 64px; /* Adjust the width and height as per your requirements */
    height: 64px;
    border-radius: 50%; /* Makes the image circular */
    overflow: hidden; /* Hides any overflow outside the circle */
    border: 2px solid  goldenrod;
  }
  
  .profile-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the container without distortion */
  }

  #radix-\:r120\: {
    background: red !important;
  }

  .loading-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }

  .loading-icon img {
    height: 100px;
    width: 100px;
  }

.Toastify__toast-container--top-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .typing {
    display: block;
    width: 60px;
    height: 40px;
    border-radius: 20px;
    margin: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circle {
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #8d8d8d;
    margin: 3px;
  }
  .circle.scaling {
    animation: typing 1000ms ease-in-out infinite;
    animation-delay: 3600ms;
  }
  .circle.bouncing {
    animation: bounce 1000ms ease-in-out infinite;
    animation-delay: 3600ms;
  }
  
  .circle:nth-child(1) {
    animation-delay: 0ms;
  }
  
  .circle:nth-child(2) {
    animation-delay: 333ms;
  }
  
  .circle:nth-child(3) {
    animation-delay: 666ms;
  }
  
  @keyframes typing {
    0% {
      transform: scale(1);
    }
    33% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    33% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
button.outline.create-campaign {
    width: 220px;
    padding: 16px;
    color: #444;
    font-size: 13pt;
    border-color: #e8c254;
    background-color: #fff9ec;
}

button.outline.create-campaign:hover {
    width: 220px;
    padding: 16px;
    color: #444;
    font-size: 13pt;
    border-color: #e8c254;
    background-color: #f7ecd5;
}

button.outline.add-appliance {
    width: 220px;
    padding: 16px;
    color: #444;
    font-size: 13pt;
    border-color: #54a0e8;
    background-color: #cdcffa;
}

button.outline.add-appliance:hover {
    width: 220px;
    padding: 16px;
    color: #444;
    font-size: 13pt;
    border-color: #2983d7;
    background-color: #9284ff;
}
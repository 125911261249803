:root {
  --tag-color: #5c8596;
  --tag-outline-color: #444;
}

.tag {
    display: inline-flex;
    padding: 8px 15px;
    border-radius: 8px;
    margin-right: 10px;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
    border: 1px solid #ccc;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.tag.tag-small {
    padding: 4px 6px;
    margin-right: 3px;
    font-size: 8.5px;
}

.tag.tag-medium {
  padding: 6px 13px;
  margin-right: 10px;
  font-size: 10px;
}

.tag.tag-large {
  padding: 6px 13px;
  margin-right: 10px;
  font-size: 10px;
}

/**/

.tag-outline-red { border-color: #fc7373; }
.tag-outline-blue { border-color: #647adf; }
.tag-outline-darkgreen { border-color: #0c5212; }
.tag-outline-purple { border-color: purple; }
.tag-outline-magenta { border-color: magenta; }
.tag-outline-orange { border-color: rgb(253, 201, 104); }
.tag-outline-burntorange { border-color: #e29742; }
.tag-outline-teal { border-color: teal; }
.tag-outline-navy { border-color: navy; }
.tag-outline-maroon { border-color: maroon; }
.tag-outline-fuchsia { border-color: rgb(211, 136, 211); }
.tag-outline-lime { border-color: #2e9c02; }
.tag-outline-olive { border-color: olive; }
.tag-outline-grey { border-color: #333; }
.tag-outline-green { border-color: #9bc99a; }

.tag-red { background-color: #fc7373; }
.tag-blue { background-color: #77c4ff; }
.tag-darkgreen { background-color: #0c5212; }
.tag-purple { background-color: purple; }
.tag-magenta { background-color: magenta; }
.tag-orange { background-color: rgb(250, 190, 79); }
.tag-burntorange { background-color: #e29742; }
.tag-teal { background-color: teal; }
.tag-navy { background-color: navy; }
.tag-maroon { background-color: maroon; }
.tag-fuchsia { background-color: rgb(243, 198, 243); }
.tag-lime { background-color: rgb(46, 156, 2); }
.tag-olive { background-color: olive; }
.tag-grey { background-color: rgb(233, 233, 233); }
.tag-green { background-color: #cff3c3; }

.tag-custom { background-color: #EAEAEA; color: black; font-weight: 600 ; }
/* .tag-outline-custom { border-color: black; } */

.tag:hover {
  transition: filter 50ms linear;
  filter: contrast(0.7) brightness(1.2)
}

.tag-remove-button {
  margin-left: 6px;
  height: 12px;
  width: 12px;
}
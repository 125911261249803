 
.recipeListRowTableRow {
    cursor: pointer;
}

.recipeListRowTableRow:hover {
    background-color: rgba(220, 220, 245, 25%);
}

.recipeListImage {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    object-fit: cover;
    border: 1px solid goldenrod;
    overflow: hidden;
}

.recipeListRowTableCell {
    font-size: 13pt;
    border-bottom: 1px solid #eee;
}

.recipeListRowTableCell.image {
    width: 5%;
    border:none;
    padding: 12px 12px 12px 12px;
}

.recipeListRowTableCell.title {
    width: 28%;
}

.recipeListRowTableCell.title:hover {
    text-decoration: underline;
}

.recipeListRowTableCell.cuisine {
    width: 10%;
}

.recipeListRowTableCell.tags {
    width: 17%;
}

.recipeListRowTableCell.applianceTags {
    width: 11%;
}

.recipeListRowTableCell.views {
    width: 10%;
    padding-left: 24px;
}

.recipeListRowTableCell.date {
    width: 10%;
}

.recipeListRowTableCell.manage {
    width: 10%;
}
.userApplianceTable {
    /* min-width: 1240px; */
}

.userApplianceTable tr:last-child td {
    border-bottom: none;
}

.userApplianceTable tr {
}

.userApplianceTable tr td {
    border-bottom: 1px solid #ccc;
}

/***/

.addApplianceDialog {
    background-color: rgba(0, 0, 0, 65%);
}

.addApplianceTable tr:last-child td {
    border-bottom: none;
}

.addApplianceTableContainer {
    border-radius: 24px;
}

.addApplianceDialog .MuiDialogContent-root {
    padding: 24px 24px 24px 24px;
}

.addApplianceDialog .MuiPaper-root {
    border: 1px solid #ddd;
    border-radius: 24px;
    box-shadow: none;
    background-color: #fafafa;
}

.addApplianceTable {
    background-color: #ffffff;
    border-radius: 24px;
    min-width: 540px;
}

.addApplianceDialogTitle.MuiTypography-root {
    padding: 24px 0px 16px 28px;
}

.addApplianceDialogActions {
    margin-right: 24px;
    margin-bottom: 16px;
}
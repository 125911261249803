.applianceRowTableRow {
    border: 0;
}

.applianceRowTableRow:hover {
    background-color: rgba(220, 220, 245, 25%)
}

.applianceRowImageContainer {
    display: block;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: linear-gradient(to top left, rgb(78, 78, 172) 30%,  lightblue 100%);
    overflow: hidden;
    border: 1px solid #666;
    transition: width 0.2s  ease-in-out, height 0.2s  ease-in-out, transform 0.3s ease-in-out;
}

.applianceImage {
    margin: 20px 0px 0px 20px;
    height: 80px;
    width: 80px;
    overflow: hidden;
}

.applianceRowTableCell {
    font-size: 13pt;
    border-bottom: 1px solid #eee;
}

.applianceRowTableCell.image {
    width: 12%;
    padding-left: 24px;
    padding-right: 24px;
}

.applianceRowTableCell.nameRow {
    width: 25%;
}

.applianceRowTableCell.modelRow {
    width: 25%;
}

.applianceRowTableCell.categoryRow {
    width: 10%;
}

.applianceRowTableCell.connectedRow {
    width: 15%;
}

.applianceRowTableCell.connectedRow.connected {
    color: rgb(0, 120, 0);
}

.applianceRowTableCell.connectedRow.disconnected {
    color: rgb(180, 0, 0);
}

.applianceRowTableCell.manageButtonRow {
    width: 10%;
}

.applianceRowTableCell.removeButtonRow {
    width: 3%;
    padding-right: 24px;
}

button.manageApplianceButton {
    width: 128px;
}

button.removeApplianceButton {
    border: 1px solid rgba(255, 255, 255, 0%);
    background: none;
    padding: 10px;
    border-radius: 60%;
    cursor: pointer;
}

button.removeApplianceButton:hover {
    border: 1px solid #ccc;
    background-color: #ff000011;
}

button.removeApplianceButton:hover .removeButtonIcon {
    color: rgb(180, 0, 0);
}

.manageButtonIcon {
    color: grey;
    padding-right: 8px;
}

.manageButtonIcon.connected {
    color: rgb(180, 0, 0);
}

.manageButtonIcon.disconnected {
    color: rgb(0, 120, 0);
}

.removeButtonIcon {
    color: grey;
    height: 20px;
    width: 20px;
}
